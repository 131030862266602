<form *ngIf="editingNote"
      class="w-100 d-flex flex-column gap-10 mb-2"
      (ngSubmit)="submitNote()"
      [formGroup]="noteForm">
  <prism-editor class="w-100" formControlName="content"></prism-editor>
  <div class="d-flex justify-content-end gap-10">
    <prism-button type="fifth" (clicked)="onCancelEdit()">Cancel</prism-button>
    <prism-button nativeType="submit">Save</prism-button>
  </div>
</form>

<div class="w-100 d-flex flex-column pb-2" *ngIf="!editingNote">
  <div class="roboto-reg-gray d-flex w-100">
    <span class="roboto-med-gray"> {{ note.author }}</span>
    <span class="pl-3">{{ note.timestamp | date: 'short': null: dateLocalePipe }}</span>

    <ng-container *ngIf="menuItems.length > 0">
      <div #menuTarget class="ml-auto pointer text-right d-print-none">
        <span class="k-icon k-font-icon k-i-more-vertical"></span>
      </div>
      <kendo-contextmenu [target]="menuTarget"
                         (select)="onContextSelect($event)"
                         [items]="menuItems"
                         showOn="click">
      </kendo-contextmenu>
    </ng-container>
  </div>

  <div class="roboto-reg-gray">
    <label class="roboto-med-gray mr-1">Note:</label>
    <span [innerHTML]="note.content | safeHtml"></span>
  </div>
</div>
